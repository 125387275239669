export function recruit(){
  const recruit = document.getElementById('recjob');
  if(recruit){
    const tabs = recruit.querySelectorAll('.joblist li a');
    tabs.forEach((el) => {
      el.addEventListener('click', clickTab);
    });
  }
};

function clickTab(e){
  const recruit = document.getElementById('recjob');
  const elem = e.target;
  const target = elem.dataset.open;
  const tabs = recruit.querySelectorAll('.joblist li a');
  const content = recruit.querySelectorAll('.detail');
  tabs.forEach((el) => {
    el.classList.remove('active');
  });
  elem.classList.add('active');

  content.forEach((el) => {
    el.classList.remove('active');
  });
  document.getElementById(target).classList.add('active');

}