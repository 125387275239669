import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
import {TweenMax} from "gsap";

export function scrolEvents(controller , scene){
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
      offset: 0,
      speed: 1000,
      easing: 'easeInOutQuart',
      speedAsDuration: true,
      durationMin: 1000
    });

    const breadArea = document.getElementById('breadarea')
    if(breadArea){
      breadArea.classList.add('active')
    }
	
    let follower = document.getElementById('follower');
    let c = document.querySelectorAll(".clickable");
    if(follower){
      let
        fWidth = 80,
        delay = 10,
        mouseX = 0,
        mouseY = 0,
        posX = 0,
        posY = 0;
  
      TweenMax.to({}, .01, {
        repeat: -1,
        onRepeat: function() {
          posX += (mouseX - posX) / delay;
          posY += (mouseY - posY) / delay;
          TweenMax.set(follower, {
            css: {
              left: posX - (fWidth / 2),
              top: posY - (fWidth / 2)
            }
          });
        }
      });
      document.body.addEventListener("mousemove", function(e){
        mouseX = e.clientX;
        mouseY = e.clientY;
      });
      
      for (let i = 0; i < c.length; i++) {
        c[i].removeEventListener("mouseenter",mouseEnter);
        c[i].removeEventListener("mouseleave",mouseLeave);
        c[i].addEventListener("mouseenter",mouseEnter);
        c[i].addEventListener("mouseleave",mouseLeave);
      }
      
      function mouseEnter(){
        follower.classList.add('active');
      }
      function mouseLeave(){
        follower.classList.remove('active');
      }
    }
}