import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {index} from './index';
import {business} from './business';
import {recruit} from './recruit';
import {vm} from './vm';


document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    
    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    business();
    recruit();
});