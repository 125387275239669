import Swiper from 'swiper/bundle';
import * as imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import {TweenMax} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function index(){
  const firstview = document.getElementById('firstview');
  const mainimg = document.getElementById('mainimg');
  const fvbox = document.querySelectorAll('.fvbox');
  const fvboxCount = fvbox.length
  let fvSwipe;
  let changeCount = 0
  if(firstview && mainimg){
    imagesLoaded(firstview, ()=>{
      setTimeout(() => {
        firstview.classList.add('active');
        fvSwipe = new Swiper(mainimg, {
          spaceBetween: 0,
          effect: 'fade',
          speed: 2000,
          loop: true,
          autoplay: {
            delay: 7000,
            disableOnInteraction: false,
          },
        });

        fvSwipe.on('slideNextTransitionStart', () => {
          if(fvSwipe.activeIndex == 5){
            fvbox[1].classList.remove('active')
          }else{
            fvbox[0].classList.remove('active')
            fvbox.forEach((e) => {
              e.classList.add('disable')
            })  
          }
        })
        fvSwipe.on('slideNextTransitionEnd', () => {
          if(fvSwipe.activeIndex == 5){
            fvbox[0].classList.add('active')
          }
          else{
            fvbox[1].classList.add('active')
          }
        })
      }, 400);
    });
  }
  

  const bnrarea = document.getElementById('bnrarea');
  if(bnrarea){
		new Swiper(bnrarea, {
      slidesPerView: 'auto',
			spaceBetween: 24,
      centeredSlides: true,
      loop: true,
			speed: 600,
      pagination: {
        el: '.bnr-pager',
        clickable: true,
      },
    })
  }

  const aircraft = document.getElementById('aircraft');
  if(aircraft){
    gsap.fromTo(
      aircraft,
      {
        top: '65%',
        left: '0%',
      },
      {

        top: '35%',
        left: '100%',
        duration: 1,
        scrollTrigger: {
          //ease: Power2.easeIn,
          trigger: '#indexClumArea',
          start: "top center",
          end: "+=250%",
          scrub: 2,
          // markers: true
        },
      }
    )
  }

};