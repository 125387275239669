import Vue from 'vue/dist/vue.min';
import axios from 'axios';

export function vm(){
  const v = new Vue({
    el: '#site-menu',
    data: {
      toggle: false
    },
  });
  const c = new Vue({
    el: '#sendMail',
    data: {
      contact: {
        name: '',
        furigana: '',
        email: '',
        tel: '',
        cate: '',
        context: '',
				token: 'xsjRV5HyfeNqF2D9VHXucy4X',
      },
      entry: {
        name: '',
        furigana: '',
        email: '',
        age: '',
        history: '',
        school: '',
        schoolafter: '',
        license: [],
        postaddr: '',
        addr: '',
        tel: '',
        pr: '',
        privacy: '',
				token: 'WP22CAs63QzZi2kYYuA8qTDf',
      },
      send: false,
			alert: false,
      message: '',
    },
		methods: {
			contactFormSend: function(){
				let obj = this.contact;
        this.send = true
        this.message = ''
				for (const [key, value] of Object.entries(obj)) {
          if(key == 'name' && value == ''){
            this.send = false
            this.addMessage('お名前')
          }
          if(key == 'tel' && value == ''){
            this.send = false
            this.addMessage('電話番号')
          }
          if(key == 'cate' && value == ''){
            this.send = false
            this.addMessage('お問い合わせ種類')
          }
          if(key == 'context' && value == ''){
            this.send = false
            this.addMessage('お問い合わせ内容')
          }
				}				
        if(!obj.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
            this.send = false;
            this.addMessage('メールアドレス')
        }
				if(this.send){
					this.alert = false;
          const post_url = '/mail/contact.php';
          const $_this = this;
          axios.post(post_url , $_this.contact)
          .then(response => {
            if(response.data == 'success'){
              location.href = "/complete/";
              return false;
            }
            if(response.data == 'error'){
              alert('送信に失敗しました');
              return false;
            }
          }).catch(error => {
            alert('送信に失敗しました');
            return false;
          });
				}else{
					this.alert = true;
				}
        this.send = false;
				return false;
			},
			entryFormSend: function(){
				let obj = this.entry;
        this.send = true
        this.message = ''
				for (const [key, value] of Object.entries(obj)) {
          if(key == 'name' && value == ''){
            this.send = false
            this.addMessage('お名前')
          }
          if(key == 'age' && value == ''){
            this.send = false
            this.addMessage('年齢')
          }
          if(key == 'license' && value.length == 0){
            this.send = false
            this.addMessage('免許取得状況','にチェックを入れてください')
          }
          if(key == 'tel' && value == ''){
            this.send = false
            this.addMessage('お電話番号')
          }
          if(key == 'pr' && value == ''){
            this.send = false
            this.addMessage('自己PRや資格等')
          }
          if(key == 'privacy' && value == false){
            this.send = false
            this.addMessage('プライバシーポリシー', 'にチェックを入れてください')
          }
				}				
        if(!obj.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
            this.send = false;
            this.addMessage('メールアドレス')
        }
				if(this.send){
					this.alert = false;
          const post_url = '/mail/entry.php';
          const $_this = this;
          axios.post(post_url , $_this.entry)
          .then(response => {
            if(response.data == 'success'){
              location.href = "/complete/";
              return false;
            }
            if(response.data == 'error'){
              alert('送信に失敗しました');
              return false;
            }
          }).catch(error => {
            alert('送信に失敗しました');
            return false;
          });
				}else{
					this.alert = true;
				}
        this.send = false;
				return false;
			},
      addMessage: function(key, value = 'に入力不備があります'){
        this.message += '<p>'+key+value+'</p>'
      }
		}
  });
};