import Swiper from 'swiper/bundle';
import anime from 'animejs';
import gsap from 'gsap';
import {TweenMax, Power2} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function business(){
  const secondTitle = document.querySelectorAll('.site-business .second__title');
  secondTitle.forEach(el => {
    gsap.fromTo(
      el,
      {
      },
      {
        transform: 'translateY(0px)',
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: el,
          start: "top bottom",
          end: "top center",
          scrub: 1,
          // markers: true
        },
      }
    )
  });
  const secondBox = document.querySelectorAll('.site-business .list__item');
  secondBox.forEach(el => {
    gsap.fromTo(
      el,
      {
      },
      {
        transform: 'translateY(0px)',
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          ease: Power2.easeIn,
          trigger: el,
          start: "top bottom",
          end: "top center",
          scrub: 1,
          // markers: true
        },
      }
    )
  });
};